export * from "ce/pages/AppIDE/components/JSAdd/getJSUrl";

import { getJSUrl as CE_getJSUrl } from "ce/pages/AppIDE/components/JSAdd/getJSUrl";
import { moduleInstanceEditorURL } from "ee/RouteBuilder";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import { FocusEntity, type FocusEntityInfo } from "navigation/FocusEntity";

export const getJSUrl = (
  item: FocusEntityInfo,
  add: boolean = true,
): string => {
  if (item.entity === FocusEntity.JS_MODULE_INSTANCE) {
    return moduleInstanceEditorURL({
      moduleInstanceId: item.id,
      add,
      moduleType: MODULE_TYPE.JS,
    });
  }

  return CE_getJSUrl(item, add);
};
