import React, { lazy, Suspense } from "react";
import Skeleton from "components/utils/Skeleton";
import { retryPromise } from "utils/AppsmithUtils";

const LazyModuleInstanceEditor = lazy(async () =>
  retryPromise(
    async () =>
      import(/* webpackChunkName: "ModuleInstanceEditor" */ "./index"),
  ),
);

const ModuleInstanceEditor = () => {
  return (
    <Suspense fallback={Skeleton}>
      <LazyModuleInstanceEditor />
    </Suspense>
  );
};

export default ModuleInstanceEditor;
