export * from "ce/pages/AppIDE/layout/routers/JSEditor/constants";

import { JSEditorRoutes as CE_JSEditorRoutes } from "ce/pages/AppIDE/layout/routers/JSEditor/constants";
import type { UseRoutes } from "IDE/Interfaces/UseRoutes";
import ModuleInstanceEditor from "ee/pages/Editor/ModuleInstanceEditor/loader";
import { MODULE_INSTANCE_ID_PATH } from "ee/constants/routes/appRoutes";
import {
  BUILDER_CUSTOM_PATH,
  BUILDER_PATH,
  BUILDER_PATH_DEPRECATED,
} from "ee/constants/routes/appRoutes";

export const JSEditorRoutes = (path: string): UseRoutes => {
  const ceRoutes = CE_JSEditorRoutes(path);

  return [
    {
      key: "ModuleInstanceEditor",
      component: ModuleInstanceEditor,
      exact: true,
      path: [
        BUILDER_PATH + MODULE_INSTANCE_ID_PATH,
        BUILDER_CUSTOM_PATH + MODULE_INSTANCE_ID_PATH,
        BUILDER_PATH_DEPRECATED + MODULE_INSTANCE_ID_PATH,
      ],
    },
    ...ceRoutes,
  ];
};
