import React from "react";
import {
  MenuItem,
  MenuSeparator,
  MenuSub,
  MenuSubContent,
  MenuSubTrigger,
  Tooltip,
} from "@appsmith/ads";

import { kebabCase, noop } from "lodash";
import type { ConvertPackageList } from "./usePackageListToConvertEntity";
import {
  CONVERT_MODULE_TO_NEW_PKG_OPTION,
  createMessage,
} from "ee/constants/messages";

export interface OnItemClickProps {
  packageId?: string;
}

interface PackageListMenuProps {
  packages: ConvertPackageList;
  isDisabled: boolean;
  onItemClick: (props: OnItemClickProps) => void;
  canCreateNewPackage: boolean;
  title: React.ReactNode;
}

function PackageListMenu({
  canCreateNewPackage,
  isDisabled,
  onItemClick,
  packages,
  title,
}: PackageListMenuProps) {
  return (
    <MenuSub data-testid="t--convert-entity-menu">
      <MenuSubTrigger
        className="convert-module-trigger"
        data-testid="t--convert-module-btn"
        disabled={isDisabled}
        startIcon="create-module"
      >
        {title}
      </MenuSubTrigger>
      <MenuSubContent>
        {packages.map((pkg) => {
          return (
            <Tooltip
              content={pkg.disabledTooltipText}
              isDisabled={!pkg.disabledTooltipText}
              key={pkg.id}
              placement="top"
            >
              <MenuItem
                data-testid={`t-add-to-${kebabCase(pkg.name)}`}
                disabled={pkg.isDisabled}
                key={pkg.id}
                onSelect={() =>
                  pkg.isDisabled ? noop : onItemClick({ packageId: pkg.id })
                }
              >
                Add to {pkg.name}
              </MenuItem>
            </Tooltip>
          );
        })}
        <MenuSeparator />
        <MenuItem
          data-testid="t-add-to-new-package"
          disabled={!canCreateNewPackage}
          onSelect={() =>
            canCreateNewPackage ? onItemClick({ packageId: undefined }) : noop
          }
        >
          {createMessage(CONVERT_MODULE_TO_NEW_PKG_OPTION)}
        </MenuItem>
      </MenuSubContent>
    </MenuSub>
  );
}

export default PackageListMenu;
