export * from "ce/pages/AppIDE/components/QueryAdd/useGroupedAddQueryOperations";

import { useModuleOptions } from "ee/utils/moduleInstanceHelpers";
import { FocusEntity } from "navigation/FocusEntity";
import { groupBy } from "lodash";
import type { GroupedAddOperations } from "IDE/Interfaces/GroupedAddOperations";
import { createMessage, EDITOR_PANE_TEXTS } from "ee/constants/messages";
import type {
  EntityGroupProps,
  EntityItemProps,
  ListItemProps,
} from "@appsmith/ads";
import {
  useAddQueryListItems,
  useGroupedAddQueryOperations as CE_useGroupedAddQueryOperations,
} from "ce/pages/AppIDE/components/QueryAdd/useGroupedAddQueryOperations";

export const useGroupedAddQueryOperations = () => {
  const [fromSourceGroup, fromBlankGroup] = CE_useGroupedAddQueryOperations();
  const { getListItems } = useAddQueryListItems();
  const moduleOperations = useModuleOptions();
  const queryModules = moduleOperations.filter(
    (fileOperations) =>
      fileOperations.focusEntityType === FocusEntity.QUERY_MODULE_INSTANCE,
  );
  const packageQueryModuleGroups = groupBy(queryModules, "focusEntityType");
  const queryModulesGroups: GroupedAddOperations = [];

  Object.entries(packageQueryModuleGroups).forEach(
    ([packageTitle, instanceOps]) => {
      queryModulesGroups.push({
        title: createMessage(EDITOR_PANE_TEXTS.queries_create_modules),
        className: `t--${packageTitle}`,
        operations: instanceOps,
      });
    },
  );

  const queryModulesGroupedItems: EntityGroupProps<
    ListItemProps | EntityItemProps
  >[] = [];

  queryModulesGroups.map((group) => {
    const items = getListItems(group.operations);

    queryModulesGroupedItems.push({
      groupTitle: group.title || "",
      className: group.className,
      items,
    });
  });

  // We still show the existing datasource group first, then add the module groups and finally the blanks group
  return [fromSourceGroup, ...queryModulesGroupedItems, fromBlankGroup];
};
