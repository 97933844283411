export * from "ce/pages/AppIDE/layout/routers/MainPane/constants";

import { MainPaneRoutes as CE_MainPaneRoutes } from "ce/pages/AppIDE/layout/routers/MainPane/constants";
import {
  MODULE_INSTANCE_ID_PATH,
  ADD_PATH,
} from "ee/constants/routes/appRoutes";
import WidgetsEditor from "pages/Editor/WidgetsEditor";

export const MainPaneRoutes = (path: string) => {
  const ceRoutes = CE_MainPaneRoutes(path);

  return [
    ...ceRoutes,
    // Module instance is on App IDE, needs canvas to be shown
    {
      key: "ModuleInstance",
      component: WidgetsEditor,
      exact: true,
      path: [
        `${path}${MODULE_INSTANCE_ID_PATH}`,
        `${path}${MODULE_INSTANCE_ID_PATH}${ADD_PATH}`,
      ],
    },
  ];
};
