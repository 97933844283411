export * from "ce/pages/AppIDE/components/QueryEntityItem/ListItem";

import React from "react";
import type { EntityItem } from "ee/IDE/Interfaces/EntityItem";
import { ActionEntityItem as CE_ActionEntityItem } from "ce/pages/AppIDE/components/QueryEntityItem/ListItem";
import { ModuleInstanceEntityItem } from "ee/pages/AppIDE/components/ModuleInstance/ModuleInstanceEntityItem";

export const ActionEntityItem = (props: { item: EntityItem }) => {
  if (props.item.isModuleInstance) {
    return <ModuleInstanceEntityItem {...props} />;
  }

  return <CE_ActionEntityItem {...props} />;
};
