import type { Page } from "entities/Page";
import React, { useCallback } from "react";
import { MenuItem, Tooltip } from "@appsmith/ads";

export const PageMenuItem = (props: {
  page: Page;
  onSelect: (id: string) => void;
  disabled?: boolean;
  tooltipText?: string;
}) => {
  const { disabled, onSelect, page, tooltipText } = props;

  const handleOnSelect = useCallback(() => {
    onSelect(page.pageId);
  }, [onSelect, page.pageId]);

  return (
    <Tooltip content={tooltipText} isDisabled={!tooltipText} placement="top">
      <MenuItem disabled={disabled} onSelect={handleOnSelect}>
        {page.pageName}
      </MenuItem>
    </Tooltip>
  );
};
