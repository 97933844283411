export * from "ce/components/BottomBar/PackageUpgradeStatus";

import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Flex, Spinner, Text } from "@appsmith/ads";
import { getPackagePullStatus } from "ee/selectors/packageSelectors";
import { PACKAGE_PULL_STATUS } from "ee/constants/ModuleConstants";
import { createMessage, PULLING_PACKAGE } from "ee/constants/messages";

const StyledWrapper = styled(Flex)`
  margin-right: var(--ads-v2-spaces-4);
`;

function PackageUpgradeStatus() {
  const packagePullStatus = useSelector(getPackagePullStatus);

  if (packagePullStatus === PACKAGE_PULL_STATUS.UPGRADING) {
    return (
      <StyledWrapper
        alignItems="center"
        data-testid="t--package-upgrade-status"
        gap="spaces-5"
      >
        <Spinner size="sm" />
        <Text color="var(--ads-v2-color-gray-500)">
          {createMessage(PULLING_PACKAGE)}
        </Text>
      </StyledWrapper>
    );
  }

  return null;
}

export default PackageUpgradeStatus;
