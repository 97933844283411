import React, { useCallback } from "react";
import { MenuItem, Tooltip } from "@appsmith/ads";
import { useDispatch } from "react-redux";
import { initExplorerEntityNameEdit } from "actions/explorerActions";
import { CONTEXT_RENAME, createMessage } from "ee/constants/messages";
import type { ModuleInstance } from "ee/constants/ModuleInstanceConstants";

interface Props {
  moduleInstance: ModuleInstance;
  disabled?: boolean;
  tooltipText?: string;
}

export const Rename = ({ disabled, moduleInstance, tooltipText }: Props) => {
  const dispatch = useDispatch();

  const setRename = useCallback(() => {
    // We add a delay to avoid having the focus stuck in the menu trigger
    setTimeout(() => {
      dispatch(initExplorerEntityNameEdit(moduleInstance.id));
    }, 100);
  }, [dispatch, moduleInstance.id]);

  return (
    <Tooltip content={tooltipText} isDisabled={!tooltipText} placement="top">
      <MenuItem
        disabled={disabled}
        onSelect={setRename}
        startIcon="input-cursor-move"
      >
        {createMessage(CONTEXT_RENAME)}
      </MenuItem>
    </Tooltip>
  );
};
