import React, { useCallback, useState } from "react";
import {
  CONFIRM_CONTEXT_DELETE,
  CONTEXT_DELETE,
  createMessage,
} from "ee/constants/messages";
import { MenuItem, Tooltip } from "@appsmith/ads";
import { useDispatch } from "react-redux";
import { deleteModuleInstance } from "ee/actions/moduleInstanceActions";
import type { ModuleInstance } from "ee/constants/ModuleInstanceConstants";

interface Props {
  moduleInstance: ModuleInstance;
  disabled?: boolean;
  tooltipText?: string;
}

export const Delete = ({ disabled, moduleInstance, tooltipText }: Props) => {
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteClick = useCallback(() => {
    dispatch(
      deleteModuleInstance({
        id: moduleInstance.id,
        type: moduleInstance.type,
      }),
    );
  }, [moduleInstance.id, moduleInstance.name, dispatch]);

  const handleSelect = useCallback(
    (e?: Event) => {
      e?.preventDefault();
      confirmDelete ? handleDeleteClick() : setConfirmDelete(true);
      e?.stopPropagation();
    },
    [confirmDelete, handleDeleteClick],
  );

  const menuLabel = confirmDelete
    ? createMessage(CONFIRM_CONTEXT_DELETE)
    : createMessage(CONTEXT_DELETE);

  return (
    <Tooltip content={tooltipText} isDisabled={!tooltipText} placement="top">
      <MenuItem
        className="t--more-action-deleteBtn t--apiFormDeleteBtn single-select error-menuitem"
        disabled={disabled}
        onSelect={handleSelect}
        startIcon="trash"
      >
        {menuLabel}
      </MenuItem>
    </Tooltip>
  );
};
