import React from "react";
import { MenuSub, MenuSubContent, MenuSubTrigger } from "@appsmith/ads";
import { useDispatch, useSelector } from "react-redux";
import { getPageList } from "selectors/editorSelectors";
import { useCallback } from "react";
import { CONTEXT_COPY, createMessage } from "ee/constants/messages";
import { copyModuleInstanceRequest } from "ee/actions/moduleInstanceActions";
import {
  ModuleInstanceCreatorType,
  type ModuleInstance,
} from "ee/constants/ModuleInstanceConstants";
import { PageMenuItem } from "./PageMenuItem";

interface Props {
  moduleInstance: ModuleInstance;
  disabled?: boolean;
  tooltipText?: string;
}

export const Copy = ({ disabled, moduleInstance, tooltipText }: Props) => {
  const menuPages = useSelector(getPageList);
  const dispatch = useDispatch();

  const copyModuleInstanceToPage = useCallback(
    (pageId: string) =>
      dispatch(
        copyModuleInstanceRequest({
          destinationContextId: pageId,
          destinationContextType: ModuleInstanceCreatorType.PAGE,
          name: moduleInstance.name,
          sourceModuleInstanceId: moduleInstance.id,
        }),
      ),
    [moduleInstance.id, moduleInstance.name, dispatch],
  );

  return (
    <MenuSub>
      <MenuSubTrigger startIcon="duplicate">
        {createMessage(CONTEXT_COPY)}
      </MenuSubTrigger>
      <MenuSubContent style={{ maxHeight: "350px" }} width="220px">
        {menuPages.map((page) => {
          return (
            <PageMenuItem
              disabled={disabled}
              key={page.basePageId}
              onSelect={copyModuleInstanceToPage}
              page={page}
              tooltipText={tooltipText}
            />
          );
        })}
      </MenuSubContent>
    </MenuSub>
  );
};
